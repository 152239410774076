:root {
  --black: #102542;
  --gray: #adadad;
  --lightGray: #d1d1d1;
  --white: #ffffff;
  --ivory: #faf7f6;
  --pink: #f36d74;
  --lightPink: #ffd2d2;
  --red: #b12028;
  --green: #01b00e;
  --gradationPink: linear-gradient(90deg, #f36d74, #f77f93);
}
