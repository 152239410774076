@use '../Global/' as *;

.l-section__wrapper {
  padding: var(--s4) 0;
}

.l-section-underlayer {
  margin-top: 180px;
  margin-bottom: 70px;

  @include MQ(md) {
    margin-top: 120px;
  }
}
