@use '../Global/' as *;

.p-about {
    min-height: 100vh;
    padding-top: 80px;

    @include MQ('lg') {
        min-height: unset;
        padding-top: 64px;
    }
}

.p-about__inner {
    padding-bottom: min(62px, 4vh);

    @include MQ('lg') {
        padding-bottom: 0;
    }
}

.p-about__container {
    margin-top: min(54px,4.2vh);

    @include MQ('lg') {
        margin-top: 32px;
    }

    .splide__pagination {
        bottom: -28px;
        left: 50%;
        transform: translateX(-50%);
    }

    .splide__pagination__page {
        width: 10px;
        height: 10px;
        background-color: var(--lightGray);
        margin-inline: 5px;
        margin-block: 0;
    }

    .splide__pagination__page.is-active {
        background-color: var(--pink);
        transform: scale(1);
    }

    .splide__track {
        overflow: visible;
    }
}

.p-about__list {
    display: flex;
    border-radius : 70px;
    column-gap: 16%;

    @include MQ('lg') {
        column-gap: 0;
    }
}

.p-about__item {
    max-height: 510px;
    height: calc(100vh - 280px);
    width: 100%;
    padding: min(100px,7vh) 48px 0 88px;
    background-color: rgba(255, 255,255, .8);
    border-radius : 70px;
    overflow: hidden;
    position: relative;

    @include MQ('lg') {
        max-height: unset;
        height: auto;
        padding: 40px 20px;
        border-radius : 24px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        row-gap: 24px;
    }
}

.p-about__body {
    position: relative;
    z-index: 1;
    width: 85%;

    @include MQ('lg') {
        width: 100%;
    }

    > *+* {
        margin-top: 24px;
    }
}

.p-about__text {
    line-height: 2.5;
    letter-spacing: .05em;

    @include MQ('xl') {
        line-height: 2;
    }

    @include MQ('lg') {
        letter-spacing: .1em;
    }
}

.p-about__text--strong {
    display: inline;
    background-color: var(--lightPink);
    padding-inline: 2px;
}

.p-about__text--paragraph {
    display: block;

    @include MQ('lg') {
        display: unset;
    }
}

.p-about__link {
    width: 250px;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 22px;
    letter-spacing: .1em;
    color: var(--red);
    border: 1px solid var(--red);
    border-radius: 100vh;
    transition: scale .3s;
    position: relative;

    @include MQ('lg') {
        margin-inline: auto;
    }

    &:hover {
        scale: 1.025;
    }

    &::after {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border-top: 1px solid var(--red);
        border-right: 1px solid var(--red);
        rotate: 45deg;
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
    }
}

.p-about__image {
    width: 38%;
    display: block;
    position: absolute;
    bottom: 0;
    right: 48px;
    z-index: 0;

    &.--flow01 {
        max-width: 372px;

        @include MQ('lg') {
            max-width: 285px;
        }
    }
    &.--flow02 {
        max-width: 412px;

        @include MQ('lg') {
            max-width: 295px;
        }
    }
    &.--flow03 {
        max-width: 392px;

        @include MQ('lg') {
            max-width: 297px;
        }
    }

    @include MQ('lg') {
        margin-inline: auto;
        position: relative;
        width: 100%;
        right: 0;
    }
}

.p-about__text--lead {
    display: none;

    @include MQ('lg') {
        display: block;
        margin-top: 32px;
        padding-inline: 17.5px;
    }
}

.p-about__text--flow03 {

    @include MQ('lg') {
        display: none;
    }
}

.p-about__progress {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
    position: relative;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);

    @include MQ('lg') {
        display: none;
    }
    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 2px;
        background-color: var(--pink);
        z-index: -1;
    }

    span {
        height: 10px;
        width: 10px;
        border-radius: 50vh;
        background-color: var(--lightGray);

        &.is-active {
            background-color: var(--pink);
        }
    }
}