@use '../Global/' as *;

.p-mv {
    position: relative;
}

.p-mv-heading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 40px;

    @include MQ('lg') {
        row-gap: 30px;
    }

    @include MQ('md') {
        row-gap: 24px;
    }
}

.p-mv-heading__main {
    color: var(--white);
    display: flex;
    align-items: baseline;
    column-gap: 32px;

    @include MQ('lg') {
        column-gap: 20px;
    }
}

.p-mv-heading__main--large {
    font-size: rem(80);
    font-weight: bold;

    @include MQ('lg') {
        font-size: rem(64);
    }

    @include MQ('md') {
        font-size: rem(40);
    }
}

.p-mv-heading__main--cross {
    font-size: rem(48);
    font-weight: 900;

    @include MQ('lg') {
        font-size: rem(40);
    }

    @include MQ('md') {
        font-size: rem(32);
    }
}

.p-mv-heading__sub {
    display: inline;
    font-size: rem(24);
    line-height: 2;
    color: var(--white);

    @include MQ('lg') {
        font-size: rem(20);
    }

    @include MQ('md') {
        font-size: rem(16);
    }
}

.p-mv-heading__sub--border {
    background-image: linear-gradient(transparent calc(100% - 1px), var(--white) 0%);
    padding-bottom: 2px;
}

.p-mv-movie {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1366 / 698;

    @include MQ('md') {
        aspect-ratio: unset;
        height: 100vh;
        height: 100dvh;
    }
}

.p-mv-movie__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}