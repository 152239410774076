@use '../Global/' as *;

.c-heading {
  @include font-default;

  &-underlayer {
    margin-bottom: 50px;

    @include MQ(md) {
      margin-bottom: 42px;
    }
  }

  &__title {
    font-size: rem(56);
    font-weight: bold;
    text-align: center;
    letter-spacing: .1em;

    @include MQ(md) {
      font-size: rem(32);
    }
  }

  &__text {
    width: fit-content;
    font-size: rem(16);
    text-align: center;
    margin-top: 16px;
    margin-inline: auto;
    position: relative;

    @include MQ(md) {
      font-size: rem(12);
      margin-top: 8px;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      background-color: var(--pink);
      border-radius: 50%;
      margin-right: 1em;
      margin-left: calc(-1em - 6px);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}