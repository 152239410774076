@use '../Global/' as *;

.p-works {
    background-color: var(--white);
    padding-block: 102px 105px;

    @include MQ('md') {
        padding-block: 52px 60px;
    }
}

.p-work-inner {
    max-width: 1100px;
    margin: 5vw auto;
}

.p-works-text {
    text-align: center;
    margin: 3rem 0 1rem 0;

    @include MQ('md') {
        line-height: 2;
        margin: 40px 0 20px 0;
    }
}

.p-works-text__onlysp {
    display: none;
}

.p-works-workcontents {
    padding-inline: 80px;
    padding-block: 100px;
    background-color: var(--white);
    border: 1px solid var(--pink);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 26px;
    row-gap: 90px;

    @include MQ('md') {
        padding-inline: 20px;
        padding-block: 48px;
        column-gap: 12px;
        row-gap: 64px;
    }
}
.p-works-worklogo {
    display: block;
    height: 90px;
    width: 180px;

    @include MQ('md') {
        height: 72px;
        width: 128px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}
.p-works-workcontents__box{
    display: flex;
    align-items: center;
}
.p-works-workcontents__img{
    width: 150px;
    height: auto;
}

@include MQ('md') {
    .p-works {
        background-color: var(--white);
    }

    .p-work-inner {
        margin: 10vw auto;
    }
}

@include MQ('lg') {
    .p-works-text__onlysp {
        display: block;
    }
}

@include MQ('xl') {
    .p-works-workcontents {
        margin: 0 3vw;
    }
}