@use '../Global/' as *;

.p-flow {
    background-color: var(--ivory);
    padding-top: 144px;
    padding-bottom: 104px;
    border-radius : 96px 96px 0px 0px;

    @include MQ('md') {
        padding-top: 54px;
        padding-bottom: 60px;
        border-radius : 24px 24px 0px 0px;
    }
}

.p-flow__inner {
    max-width: 1132px;
}

.p-flow__list {
    margin-top: 32px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    column-gap: 7px;
    row-gap: 9px;

    @include MQ('lg') {
        padding-right: 20px;
    }

    @include MQ('sm') {
        padding-right: 0;
    }
}

.p-flow__item {
    width: calc(100% / 5 - (28px / 5));
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    background-color: var(--white);
    border: 1px solid var(--pink);
    padding-block: 28px;
    padding-inline: 4px;
    position: relative;

    @include MQ('lg') {
        width: calc(100% / 3 - (14px / 3));
    }

    @include MQ('md') {
        width: calc(100% / 2 - (14px / 2));
    }

    @include MQ('sm') {
        width: 100%;
        padding-block: 18px 28px;
        row-gap: 6px;
    }

    &:not(:last-child) {
        &::before {
            content: '';
            display: block;
            width: 22px;
            height: 30px;
            background-image: url(../images/flow/flow-arrow.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            position: absolute;
            top: 50%;
            right: -22px;
            transform: translateY(-50%);
            z-index: 10;

            @include MQ('sm') {
                width: 20px;
                height: 28px;
                background-size: cover;
                top: unset;
                bottom: -14px;
                right: unset;
                left: calc(50% - 10px);
                transform: rotate(90deg) translateX(50%);
            }
        }
    }
}

.p-flow__label {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(16);
    font-weight: bold;
    color: var(--white);
    letter-spacing: .1em;
    background-image: var(--gradationPink);
    border-radius: 50%;
}

.p-flow__title {
    font-size: rem(16);
    font-weight: bold;
    letter-spacing: .1em;
    text-align: center;

    @include MQ('sm') {
        font-size: rem(20);
    }
}

.p-flow__text {
    font-size: rem(14);
    letter-spacing: .1em;
    line-height: 1.72;
    text-align: center;
}