@use '../Global/' as *;

.l-footer {
  @include font-default;

  &--top {
    margin-top: -270px;
  }

  &__background {
    border-radius: 100px 100px 0 0;
    padding: 65px 0 40px;

    @include MQ(md) {
      border-radius: 50px 50px 0 0;
      padding: 60px 0 20px;
    }
  }

  &__wrapper {
    margin: 0 auto;
    padding: 0 28px;
    max-width: 1200px;
    width: 100%;

    @include MQ(sm) {
      padding-right: 16px;
    }
  }

  &__contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    @include MQ(md) {
      flex-wrap: wrap;
      row-gap: 44px;
      margin-bottom: 0px;
    }
    @include MQ(sm) {
      display: block;
    }
  }

  &__logo {
    margin-right: 24px;

    @include MQ(sm) {
      margin-right: 0;
    }

    img {
      max-width: 160px;

      @include MQ(md) {
        max-width: 110px;
      }
    }
  }

  &__address {
    font-size: rem(14);
    margin-top: 24px;
  }

  &__nav {
    max-width: 577px;

    @include MQ(lg) {
      display: flex;
    }
    @include MQ(sm) {
      margin-top: 44px;
    }
  }

  &__list {
    display: flex;
    max-width: 577px;
    margin-bottom: 40px;
    flex-wrap: wrap;

    @include MQ(lg) {
      display: block;
      margin-bottom: 0;
      margin-right: 7.5vw;
    }
    @include MQ(sm) {
      width: calc(100%/2);
    }

    &:last-child {
      margin-bottom: 0;

      @include MQ(lg) {
        margin-right: 0;
      }
    }
  }

  &__item {
    margin-right: 42px;
    font-size: rem(14);
    font-weight: bold;

    @include MQ(xxxl) {
      margin-right: 2.4vw;
    }
    @include MQ(lg) {
      margin-right: 0;
      margin-bottom: 20px;
    }

    &:last-child {
      margin-right: 0;

      @include MQ(lg) {
        margin-bottom: 0;
      }
    }
  }
  &__item--sns {
    width: 16px;
    height: 16px;
    vertical-align: middle;

    @include MQ(sm) {
      width: 14px;
      height: 14px;
    }
  }

  &__copyright {
    font-size: rem(12);
    text-align: right;
    margin-top: 20px;
  }
}
.l-footer__list--instagram{
  display: block;
  margin-top: 40px;
  @include MQ(lg) {
    margin-top: 0px;
  }
  @include MQ(sm) {
    width: 100%;
  }
}
.l-footer__item--sns__onlysp{
  display: none;
  @include MQ(lg) {
    display: block;
  }
}
