@use '../Global/' as *;

.p-thanks {
    text-align: center;

    &__content {
        padding: 120px 26px 120px;

        @include MQ(md) {
            padding: 64px 26px 64px;
        }
    }
}

.p-thanks__content__checkmark {
    width: 70px;
    height: 70px;
    display: block;
    margin-inline: auto;
    position: relative;

    @include MQ(md) {
        width: 40px;
        height: 40px;
    }
}

.p-thanks__content__checkmark:before {
    content: "";
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: var(--pink);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include MQ(md) {
        width: 40px;
        height: 40px;
    }
}

.p-thanks__content__checkmark:after {
    content: "";
    display: block;
    position: absolute;
    border-left: 3px solid var(--white);
    border-bottom: 3px solid var(--white);
    width: 30px;
    height: 13px;
    transform: translate(-50%, -50%) rotate(-45deg);
    left: 50%;
    top: 50%;

    @include MQ(md) {
        border-width: 2px;
        width: 18px;
        height: 8px;
    }
}
.p-thanks__content__text--top{
    text-decoration-line: underline;
    text-underline-offset: 0.5rem;
    text-decoration-thickness: 0.05rem;
    padding: 3rem 0 2rem 0;
    color: var(--pink);
    font-size: 24px;
    font-weight: 600;

    @include MQ(md) {
        font-size: 16px;
    }
}
.p-thanks__content__text--bottom{
    line-height: 2;
}