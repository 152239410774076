@use '../Global/' as *;

.u-uppercase {
  text-transform: uppercase;
}

.u-lowercase {
  text-transform: lowercase;
}

.u-centerposition {
  display: flex;

  justify-content: center;
  align-items: center;
}

.u-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  white-space: nowrap;

  clip-path: inset(50%);
}

.u-bold {
  font-weight: bold;
}

.u-color\:white {
  color: #fff;
}

.u-block {
  display: block;
}

.u-left {
  text-align: left;
}

.u-center {
  text-align: center;
}

.u-font-size\:smallish {
  font-size: var(--font-size-smallish);
}

.u-font-size\:biggish {
  font-size: var(--font-size-biggish);
}

.u-font-size\:big {
  font-size: var(--font-size-big);
}

.u-pc {
  display: block;

  @include MQ('md') {
    display: none;
  }
}

.u-sp {
  display: none;

  @include MQ('md') {
    display: block;
  }
}

.u-sm--none {
  display: block;

  @include MQ('sm') {
    display: none;
  }
}