@use '../Global/' as *;

.p-menu {
    padding-top: 416px;
    padding-bottom: 64px;
    margin-top: -216px;
    background-image: url(../images/top/menu/bg.jpg);
    background-size: cover;
    background-position: bottom center;
    position: relative;
    z-index: 1;

    @include MQ('md') {
        padding-top: 108px;
        margin-top: -48px;
        padding-bottom: 80px;
    }

    &::after {
        content: "";
        display: block;
        width: 240px;
        height: 64px;
        background-color: #FDE1E0;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        position: absolute;
        bottom: -64px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        @include MQ('md') {
            width: 70px;
            height: 18px;
            bottom: -18px;
        }
    }
}

.p-menu__inner {
    padding-top: 100px;
    margin-top: -100px;

    @include MQ('md') {
        padding-top: 60px;
        margin-top: -60px;
    }
}

.p-menu-navigation {
    margin-top: 52px;
    padding: 40px 96px;
    background-color: rgba(255, 255, 255, .6);
    border-radius: 32px;

    @include MQ('lg') {
        padding: 32px 56px;
    }

    @include MQ('md') {
        margin-top: 36px;
        padding: 80px 12px;
        border-radius : 24px;
    }
}

.p-menu-navigation__inner {

    &.splide__track--nav>.splide__list>.splide__slide.is-active {
        border: none;
    }
    &.splide__track--nav>.splide__list>.splide__slide {
        border: none;
        cursor: unset;
    }
}

.p-menu-navigation__list {

    &.splide__list {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(3, auto);
        row-gap: 16px;

        @include MQ('md') {
            grid-auto-flow: row;
            grid-template-columns: 1fr;
            row-gap: 24px;
        }
    }
}

.p-menu-navigation__item {
    font-weight: 500;

    @include MQ('md') {
        text-align: center;
    }
}

.p-menu-navigation__button {
    padding-right: 20px;
    position: relative;
    transition: color .3s;
    
    @include MQ('md') {
        margin-right: -20px;
    }

    &:hover {
        opacity: 1;
        color: var(--pink);
    }

    &::before {
        content: "";
        display: block;
        width: 5px;
        height: 9px;
        background-image: url(../images/top/menu/nav-arrow.svg);
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.p-menu-content {

    &[data-menu="disable"] {
        z-index: -1;
        height: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        transition: height 0s, opacity 0s, visibility 0s;
    }
    &[data-menu="visible"] {
        z-index: 1000;
        height: auto;
        opacity: 1;
        visibility: visible;
        overflow: visible;
        transition: height .2s, opacity .2s, visibility .2s;
    }

    &.splide {
        position: absolute;
        width: calc(100vw - 32px);
        max-width: 1100px;
        left: 50%;
        top: calc(100% + 160px);
        transform: translateX(-50%);
        background-color: var(--white);
        border-radius: 32px;
        box-shadow: 0 0 10px rgba(243, 109, 116, .15);
        padding: 32px 48px 128px;

        @include MQ('md') {
            height: 570px;
            width: calc(100vw - 10px);
            top: 170px;
            border-radius: 24px;
            padding: 20px 28px 0;
        }
    }

    .splide__arrows {

        @include MQ('md') {
            position: absolute;
            bottom: -37px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .splide__arrow {
        background-color: transparent;
        background-image: var(--gradationPink);
        opacity: 1;
        width: 48px;
        height: 48px;
        transform-origin: center center;
        transition: transform .3s;

        @include MQ('md') {
            width: 32px;
            height: 32px;
        }

        &::before {
            content: "";
            display: block;
            width: 7px;
            height: 16px;
            background-image: url(../images/top/menu/button-arrow.svg);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            left: 19px;

            @include MQ('md') {
                width: 8px;
                height: 12px;
                left: 11px;
            }
        }
    }

    .splide__arrow--prev {
        left: -24px;

        @include MQ(xl) {
            left: -12px;
        }

        @include MQ(md) {
            left: -138px;
        }
    }

    .splide__arrow--next {
        right: -24px;

        @include MQ(xl) {
            right: -12px;
        }

        @include MQ(md) {
            right: -138px;
        }

        &::before {
            rotate: 180deg;
            left: unset;
            right: 19px;

            @include MQ('md') {
                left: unset;
                right: 11px;
            }
        }
    }

    .splide__arrow:hover:not(:disabled) {
        opacity: 1;
        transform: translateY(-50%) scale(1.1);
    }

    .splide__pagination {
        bottom: -44px;
    }
    .splide__pagination__page {
        width: 10px;
        height: 10px;
        margin: 0 5px;
        background-color: var(--lightGray);
    }
    .splide__pagination__page.is-active {
        background-color: var(--pink);
        transform: scale(1);
    }
}

.p-menu-content__list {

}

.p-menu-content__close {
    display: block;
    width: fit-content;
    margin-left: auto;
    font-size: rem(26);
    line-height: 1;

    @include MQ('md') {
        position: absolute;
        z-index: 10;
        top: 16px;
        right: 20px;
    }
}

.p-menu-content__caution {
    margin-top: 32px;
    font-size: rem(12);
    letter-spacing: .1em;

    @include MQ('md') {
        margin-top: 0;
    }
}

.p-menu-content__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
    margin-top: 6px;

    @include MQ('md') {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
}

.p-menu-content-body {
    grid-area: 1 / 2 / 2 / 3;
    margin-top: 40px;

    @include MQ('md') {
        grid-area: 2 / 1 / 3 / 2;;
        margin-top: 0;
    }
}

.p-menu-content-body__en {
    font-size: rem(24);
    font-weight: bold;
    color: var(--pink);
    letter-spacing: .1em;

    @include MQ('md') {
        font-size: rem(14);
    }
}

.p-menu-content-body__title {
    font-size: rem(32);
    font-weight: bold;
    color: var(--pink);
    letter-spacing: .1em;
    margin-top: 8px;

    @include MQ('md') {
        font-size: rem(20);
        margin-top: 4px;
    }
}

.p-menu-content-body__text {
    margin-top: 16px;
    font-size: rem(16);
    line-height: 2;
    letter-spacing: .1em;

    @include MQ('md') {
        margin-top: 8px;
        font-size: rem(14);
        line-height: 1.715;
    }
}

.p-menu-content-body__info {
    margin-top: 88px;
    border-top: 1px dashed var(--gray);

    @include MQ('md') {
        margin-top: 14px;
    }
}

.p-menu-content-body__info-wrap {
    display: grid;
    grid-template-columns: 150px 1fr;
    padding: 8px 12px;
    border-bottom: 1px dashed var(--gray);

    @include MQ('md') {
        grid-template-columns: 110px 1fr;
        padding: 8px 4px;
    }
}

.p-menu-content-body__info-title {
    font-size: rem(16);
    font-weight: bold;
    color: var(--pink);
    letter-spacing: .1em;

    @include MQ('md') {
        font-size: rem(14);
    }
}

.p-menu-content-body__info-text {
    font-size: rem(16);
    letter-spacing: .1em;

    @include MQ('md') {
        font-size: rem(14);
    }
}

.p-menu-content-image {
    grid-area: 1 / 1 / 2 / 2;

    @include MQ('md') {
        grid-area: 1 / 1 / 2 / 2;
        aspect-ratio: 3 / 2;
        max-width: 324px;
        margin-inline: auto;
    }

    img {

        @include MQ('md') {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}


.p-menu-add {
    background-color: var(--ivory);
    padding-block: 136px 296px;
    position: relative;
    z-index: 0;

    @include MQ('lg') {
        padding-block: 116px 96px;
    }

    @include MQ('md') {
        padding-block: 52px;
    }
}

.p-menu-add__inner {
    max-width: 1132px;
}

.p-menu-add__flow {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 220px);
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    row-gap: 24px;
    list-style: none;
    position: relative;
    z-index: 0;

    @include MQ('lg') {
        width: max-content;
        max-width: 100%;
        margin-inline: auto;
        grid-template-columns: 1fr;
        row-gap: 18px;
    }

    &::before {
        content: "";
        display: block;
        width: calc(100% - 220px);
        height: 1px;
        background-color: var(--pink);
        position: absolute;
        top: 84px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        @include MQ('lg') {
            display: none;
        }
    }
}

.p-menu-add__content {
    display: grid;
    justify-items: center;
    grid-template-rows: subgrid;
    grid-row: span 3;
    row-gap: 0;

    @include MQ('lg') {
        display: flex;
        column-gap: 20px;
    }

    &:not(:last-child) {

        &::after {

            @include MQ('lg') {
                content: "";
                display: block;
                width: 1px;
                height: 100%;
                background-color: var(--pink);
                position: absolute;
                top: 0;
                left: 84px;
                z-index: -1;
            }

            @include MQ('md') {
                left: 60px;
            }
        }
    }
}

.p-menu-add__content--mark {
    display: contents;

    @include MQ('lg') {
        display: block;
    }
}

.p-menu-add__image {
    display: flex;
    aspect-ratio: 1 / 1;
    width: 168px;

    @include MQ('md') {
        width: 120px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.p-menu-add__label {
    margin-top: -12px;
    margin-inline: auto;
    width: 88px;
    font-size: rem(16);
    font-weight: bold;
    letter-spacing: .1em;
    line-height: 44px;
    border-radius: 50vh;
    text-align: center;
    color: var(--white);
    background-image: var(--gradationPink);
    position: relative;
}

.p-menu-add__list {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;

    @include MQ('lg') {
        margin-top: 16px;
        align-items: flex-start;
        row-gap: 6px;
    }
}

.p-menu-add__item {

    @include MQ('xl') {
        font-size: rem(14);
    }
}

.p-menu-add__title {
    font-size: rem(32);
    font-weight: bold;
    letter-spacing: .1em;
    text-align: center;
    color: var(--pink);

    @include MQ('md') {
        font-size: rem(16);
    }
}