@use '../Global/' as *;

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
}

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

h2 {
  font-size: 10rem;
}

html {
  font-size: $basefontsize + px;
  font-family: $font-family;
  // scroll-behavior: smooth!important;
}

body {
  background: url('../images/common/bg_pc.png');
  background-size: 100% auto;
  color: var(--black);
  background-repeat: repeat-y;
  background-position-y: -300px;
  background-attachment: fixed;

  @include MQ(md) {
    background: url('../images/common/bg_sp.png');
    background-size: 100% auto;
    background-repeat: repeat-y;
    background-position-y: -265px;
    background-attachment: fixed;
  }
}

main {
  overflow-x: hidden;

  flex: 1;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;

  @include font-default;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

