@use '../Global/' as *;

.p-point {
    background-color: var(--white);
    border-radius : 96px;
    margin-top: 108px;
    padding-top: 160px;
    position: relative;
    z-index: 2;

    @include MQ('md') {
        border-radius : 24px;
        margin-top: 65px;
        padding-top: 60px;
    }
}

.p-point__content {
    display: block;
}

.p-point__content--map {
    margin-top: 40px;

    .p-point__item {
        display: flex;

        @include MQ('lg') {
            flex-direction: column;
            align-items: center;
        }
    }
}

.p-point__content--watch {
    margin-top: 92px;
    padding-top: 96px;
    position: relative;
    z-index: 0;

    @include MQ('md') {
        margin-top: 42px;
        padding-top: 52px;
    }

    &::before {
        content: '';
        width: 100vw;
        height: 100%;
        background-color: var(--ivory);
        border-radius : 0px 96px 0px 0px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;

        @include MQ('md') {
            right: -10px;
            border-radius : 0px 100px 0px 0px;
        }
    }
    
    .p-point__item {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        column-gap: 104px;

        @include MQ('lg') {
            flex-direction: column;
            align-items: center;
        }
    }
}

.p-point__content--promotion {
    margin-top: 80px;

    @include MQ('md') {
        margin-top: 54px;
    }

    .p-point__body {

        @include MQ('lg') {
            width: fit-content;
            margin-inline: auto;
        }
    }
}

.p-point__label {
    font-size: rem(32);
    font-weight: bold;
    letter-spacing: .1em;
    color: var(--pink);

    @include MQ('md') {
        font-size: rem(20);
    }
}

.p-point__title {
    font-size: rem(32);
    font-weight: bold;
    letter-spacing: .1em;
    color: var(--pink);

    @include MQ('md') {
        font-size: rem(20);
    }
}

.p-point__text {
    margin-top: 24px;
    font-size: rem(16);
    line-height: 2.5;
    letter-spacing: .1em;

    @include MQ('md') {
        margin-top: 8px;
        line-height: 2;
    }
}

.p-point__text--strong {
    display: inline;
    background-color: var(--lightPink);
    padding-inline: 2px;
}

.p-point__map {
    margin-top: 40px;
    width: fit-content;
    position: relative;

    @include MQ('md') {
        margin-top: 46px;
    }
}

.p-point__map--main {
    display: block;
    width: 400px;
    opacity: 0;

    @include MQ('md') {
        width: 350px;
    }
}

.p-point__map--pin img {
    width: 20px;
    position: absolute;
    top: calc(var(--top) - 18px);
    right: var(--right);
    opacity: 0;

    @include MQ('md') {
        width: 18px;
    }

    &:nth-of-type(1) {
        --top: 18px;
        --right: 70px;

        @include MQ('md') {
            --top: 20px;
            --right: 60px;
        }
    }
    &:nth-of-type(2) {
        --top: 102px;
        --right: 102px;

        @include MQ('md') {
            --top: 89.5px;
            --right: 90.5px;
        }
    }
    &:nth-of-type(3) {
        --top: 138px;
        --right: 126px;

        @include MQ('md') {
            --top: 121px;
            --right: 110px;
        }
    }
    &:nth-of-type(4) {
        --top: 174px;
        --right: 120px;

        @include MQ('md') {
            --top: 155px;
            --right: 105px;
        }
    }
    &:nth-of-type(5) {
        --top: 194px;
        --right: 128px;

        @include MQ('md') {
            --top: 171px;
            --right: 114px;
        }
    }
    &:nth-of-type(6) {
        --top: 204px;
        --right: 140px;

        @include MQ('md') {
            --top: 182px;
            --right: 125px;
        }
    }
    &:nth-of-type(7) {
        --top: 174px;
        --right: 152px;

        @include MQ('md') {
            --top: 152px;
            --right: 134px;
        }
    }
    &:nth-of-type(8) {
        --top: 192px;
        --right: 168px;

        @include MQ('md') {
            --top: 168px;
            --right: 149px;
        }
    }
    &:nth-of-type(9) {
        --top: 176px;
        --right: 202px;

        @include MQ('md') {
            --top: 154px;
            --right: 175.5px;
        }
    }
    &:nth-of-type(10) {
        --top: 213px;
        --right: 210px;

        @include MQ('md') {
            --top: 187px;
            --right: 184px;
        }
    }
    &:nth-of-type(11) {
        --top: 192px;
        --right: 234px;

        @include MQ('md') {
            --top: 167px;
            --right: 207px;
        }
    }
    &:nth-of-type(12) {
        --top: 242px;
        --right: 306px;

        @include MQ('md') {
            --top: 211px;
            --right: 267px;
        }
    }

}

.p-point__watch {
    width: fit-content;
    position: relative;
    margin-top: 110px;

    @include MQ('md') {
        margin-top: 50px;
    }
}

.p-point__watch--main {
    display: flex;
    width: 404px;
    opacity: 0;

    @include MQ('md') {
        width: 330px;
    }
}

.p-point__watch--batch {
    width: 168px;
    position: absolute;
    top: -20px;
    right: 50px;
    opacity: 0;
    scale: .9;
    transform-origin: left bottom;

    @include MQ('md') {
        width: 138px;
        right: 40px;
    }
}

.p-point__promotion {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 30px;

    @include MQ('lg') {
        flex-direction: column;
        justify-content: center;
        align-items: unset;
        margin-top: 40px;
    }
}

.p-point__promotion-image {
    width: fit-content;
    position: relative;

    @include MQ('lg') {
        margin-left: auto;
        margin-right: 80px;
    }

    @include MQ('md') {
        margin-left: auto;
        margin-right: 10px;
    }
}

.p-point__promotion--main {
    display: flex;
    width: 400px;
    opacity: 0;

    @include MQ('md') {
        width: 260px;
    }
}

.p-point__promotion--person {
    display: flex;
    width: 225px;
    position: absolute;
    bottom: 0;
    left: 44px;
    opacity: 0;
    scale: .9;
    transform-origin: right bottom;

    @include MQ('md') {
        width: 146px;
        left: 36px;
    }
}

.p-point__promotion-graph {
    display: flex;
    column-gap: 20px;
    margin-bottom: 64px;

    @include MQ('lg') {
        justify-content: center;
    }

    @include MQ('md') {
        margin-bottom: 48px;
    }

    img {
        opacity: 0;
        width: 200px;

        @include MQ('md') {
            width: 145px;
        }

        &:nth-of-type(1) {
            margin-top: 20px;
        }

        &:nth-of-type(2) {
            margin-top: 58px;

            @include MQ('md') {
                margin-top: 20px;
            }
        }
    }
}

.p-point__promotion-graph-content {
    position: relative;
    opacity: 0;
    width: 200px;
    height: 200px;

    @include MQ('md') {
        width: 145px;
        height: 145px;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    &:nth-of-type(1) {
        margin-top: 20px;
        margin-left: 200px;

        @include MQ('lg') {
            margin-left: 0;
        }
    }

    &:nth-of-type(2) {
        margin-top: 58px;

        @include MQ('md') {
            margin-top: 20px;
        }
    }
}

.p-point__promotion-graph-circle {
    rotate: -90deg;
}

.p-point__number-item {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    flex-wrap: wrap;
    color: var(--pink);
    line-height: 1;
}

.p-point__number-main {
    font-size: rem(72);
    font-weight: bold;

    @include MQ('md') {
        font-size: rem(55.85);
    }
}

.p-point__number-unit {
    font-size: rem(32);
    font-weight: bold;

    @include MQ('md') {
        font-size: rem(24);
    }
}

.p-point__number-label {
    width: 100%;
    color: var(--pink);
    font-size: rem(24);
    font-weight: bold;
    text-align: center;
    line-height: 1;

    @include MQ('md') {
        font-size: rem(16);
        text-align: left;
        padding-left: 8px;
    }
}

.p-point__map-number {
    position: absolute;
    right: 0;
    bottom: 30px;

    @include MQ('md') {
        bottom: 20px;
        right: 20px;
    }
}

.p-point__watch-number {
    width: 165px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    right: -32px;
    bottom: 16px;

    @include MQ('md') {
        width: 128px;
        right: 0;
        bottom: 20px;
    }
}