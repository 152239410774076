@use '../Global/' as *;

.l-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 992px;
  width: 100%;

  @include MQ('md') {
    padding: 0 10px;
  }
}

.l-wrapper-sm {
  position: relative;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 952px;
  width: 100%;

  @include MQ('md') {
    padding: 0 10px;
  }
}

.l-wrapper-front {
  position: relative;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1032px;
  width: 100%;

  @include MQ('md') {
    padding: 0 10px;
  }
}