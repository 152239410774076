@use '../Global/' as *;

.l-header {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;

    @include MQ(lg) {
        height: 52px;
    }

    &__background {
        border-radius: 0 0 40px 40px;
        padding: 12px 0;

        @include MQ(lg) {
            border-radius: 0 0 24px 24px;
            padding: 6px 0;
        }
    }

    &__wrapper {
        margin: 0 auto;
        padding: 0 16px;
        max-width: 1200px;
        width: 100%;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__utilities {
        display: flex;
        align-items: center;

        @include MQ(lg) {
            position: fixed;
            z-index: 9999;
        }
    }

    &__logo {
        margin-right: 5.5vw;

        img {
            width: 100px;
            height: auto;

            @include MQ(lg) {
                width: 78px;
            }
        }
    }

    &__nav {
        @include MQ(lg) {
            display: none;
        }
    }

    &__list {
        display: flex;
    }

    &__item {
        font-size: rem(14);
        font-weight: bold;
        margin-right: 56px;

        @include MQ(xxxl) {
            margin-right: 3vw;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        color: var(--black);
        transition: color 0.3s;

        &:hover {
            opacity: 1;
            color: var(--pink);
        }
    }

    &__contact {
        display: flex;
        align-items: center;

        @include MQ(lg) {
            margin-left: auto;
        }
    }

    &__contact--link {
        font-size: rem(14);
        font-weight: bold;
        color: var(--white);
        background: var(--gradationPink);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 136px;
        height: 48px;
        border-radius: 50px;
        margin-right: 16px;
        letter-spacing: 1.4px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__contact--link--inner {
        width: 100%;
        text-align: center;
        letter-spacing: 1.4px;
        height: 46px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.4s;

        &:hover {
            background: var(--white);
            border-radius: 50px;
            color: var(--pink);
            padding: 13px 13px;
            width: calc(100% - 3px);
            height: 95%;
        }
    }

    &__contact--link-pc {
        @include MQ(lg) {
            display: none;
        }
    }

    &__contact--white {
        background: var(--gradationPink);
        color: var(--pink);
        border-radius: 50px;
        padding: 1px;
        background-image: var(--gradationPink);
        border-radius: 50px;
    }

    &__contact--white--inner {
        background: var(--white);
        border-radius: 50px;
        height: 99%;
        width: 100%;
        text-align: center;
        letter-spacing: 1.4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.4s;

        &:hover {
            background: var(--gradationPink);
            color: var(--white);
        }
    }

    &__sp {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        width: 100%;
        transform: translateX(100%);
        background-color: #fff;
        transition: ease .4s;
        z-index: 999;
        border-radius: 0 0 24px 24px;

        &.active {
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
            height: 100%;
            overflow-y: scroll;
        }
    }

    &__sp--inner {
        padding: 10px 10px 64px;
        max-width: 300px;
        margin: 0 auto;
    }

    &__sp--logo {
        position: fixed;
        left: 16px;
        
        img {
            max-width: 78px;
        }
    }

    &__sp--list {
        margin-top: 98px;
    }

    &__sp--item {
        background-image: linear-gradient(to right, var(--gray) 1px, transparent 1px);;
        background-size: 6px 2px;
        background-repeat: repeat-x;
        background-position: left bottom;
        text-align: center;

        &:last-child {
            background-image: none;
        }
    }

    &__sp--link {
        padding: 8px 10px 10px;
        display: block;
        height: fit-content;
    }

    &__sp--text-title {
        font-size: rem(24);
        font-weight: bold;
    }

    &__sp--text-lead {
        font-size: rem(12);

        &::before {
            content: "";
            display: inline-block;
            width: 6px;
            height: 6px;
            background-color: var(--pink);
            border-radius: 50%;
            margin-right: 1em;
            margin-left: calc(-1em - 6px);
        }
    }

    &__sp--contact {
        display: flex;
        align-items: center;
        margin-top: 58px;
    }
}

.l-hamburger {
    display: none;

    @include MQ(lg) {
        display: block;
        width: 20px;
        height: fit-content;
        z-index: 9999;
    }

    span {
        width: 100%;
        height: 1px;
        background-color: var(--black);
        position: relative;
        transition: ease .4s;
        display: block;

        &:nth-child(1) {
            top: 0;
        }
        &:nth-child(2) {
            margin: 8px 0;
        }
        &:nth-child(3) {
            top: 0;
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                top: 5px;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                top: -13px;
                transform: rotate(-45deg);
            }
        }
    }
}
