@use '../Global/' as *;

.p-company {

    &__background {
        padding: 80px 120px;

        @include MQ(md) {
            padding: 40px 24px;
        }
    }

    &__item {
        padding: 30px 30px;
        display: flex;
        background-image : linear-gradient(to right, var(--gray) 1px, transparent 1px);
        background-size: 5px 1px;
        background-repeat: repeat-x;
        background-position: left bottom;
        
        @include MQ(md) {
            padding: 24px 10px;
        }
        @include MQ(sm) {
            background-size: 6px 2px;
            display: block;
        }
    }

    &__item--heading {
        color: var(--pink);
        font-weight: bold;
        width: 36%;

        @include MQ(sm) {
            width: 100%;
        }
    }

    &__item--body {
        width: 64%;
        line-height: 1.8;
        letter-spacing: 0.1em;
        // &__letterspace{
        //     letter-spacing: 0.1em;
        // }
        @include MQ(sm) {
            width: 100%;
            margin-top: 12px;
        }
    }

    &__item--link {
        color: var(--green);
        text-decoration: underline;
    }

    &__item--images {
        max-width: 240px;
        height: auto;
    }
}
.p-company__item--body a:not(.p-company__item--link) {
    color: var(--red);
    text-decoration: underline;
}