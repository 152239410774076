@use '../Global/' as *;

.c-button {
  
  &__text {
    position: relative;
    font-weight: bold;
    color: var(--white);
    background: var(--gradationPink);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 84.2105px;
    width: 294.7368px;
    padding: 16px;
    border-radius: 50px;
    margin: 0 auto;
    letter-spacing: 1.4px;
    scale: .95;
    transition: scale 0.4s;

    &::after {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 0 10px;
      border-top: 2px solid var(--white);
      border-right: 2px solid var(--white);
      transform: rotate(45deg);
      position: absolute;
      right: 28px;
    }

    &:hover {
      scale: 1;
    }
  }
}