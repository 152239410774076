@use '../Global/' as *;

.p-privacy {

    &__background {
        padding: 80px 80px;

        @include MQ(md) {
            padding: 66px 24px;
        }
    }

    &__wrapper {
        margin-bottom: 80px;

        @include MQ(md) {
            margin-bottom: 56px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-size: rem(32);
        color: var(--pink);

        @include MQ(md) {
            font-size: rem(20);
        }
    }

    &__text {
        margin-top: 32px;
        line-height: 1.8;

        @include MQ(md) {
            margin-top: 24px;
        }
    }

    &__list {
        margin-top: 46px;

        @include MQ(md) {
            margin-top: 24px;
        }
    }

    &__item {
        margin-bottom: 20px;
        line-height: 1.8;
        letter-spacing: .1em;
        padding-left: 1.5em;
        position: relative;

        @include MQ(md) {
            margin-bottom: 12px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__number {
        position: absolute;
        left: 0;
    }
}