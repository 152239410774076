@use '../Global/' as *;

.p-news {
    background-color: var(--white);
    padding-block: 128px 108px;

    @include MQ('md') {
        padding-block: 48px 46px;
    }
}

.p-news-inner {
    max-width: 1000px;
    margin: 0 auto;
}

.p-news-newstxt {
    margin-bottom: 3rem;

    &::before {
        content: "・";
        color: var(--pink);
        margin-right: 0.2rem;
    }
}

.p-news-newstitle,
.p-news-newstxt {
    display: block;
    text-align: center
}

.p-news-newscontents {
    background-image : linear-gradient(to right, var(--gray) 1px, transparent 1px);;
    background-size: 5px 1px;;
    background-repeat: repeat-x;
    background-position: left bottom;

    @include MQ('md') {
        background-size: 6px 2px;
    }

    .p-news-newscontents__left {
        display: flex;

        &__display__title {
            font-size: rem(16);
            margin-left: 2rem;
            font-weight: normal;
        }

        &__display__date {
            color: var(--pink);
            font-weight: bold;

            @include MQ('md') {
                font-size: rem(14);
            }
        }
    }
}

.p-news__newsbutton {
    display: block;
    margin: 0 auto;
}

.p-news-newsmain {
    padding: 28px 36px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
}

.p-news-newscontents__displaynone {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    margin-right: 3.75rem;
    margin-left: 154px;
    transition: opacity .6s ease;

    a {
        text-decoration: underline;
    }
}

[data-news="open"] {
    &.p-news-newscontents {
        padding-bottom: 28px;
    }
    .p-news-newsmain {
        padding-bottom: 24px;
    }
    .p-news-newscontents__displaynone {
        opacity: 1;
        transition: opacity .6s ease;
    }
    .p-news-newscontents__display__botton {
        transform: rotate(225deg);
    }
}

// 矢印ボタン用スタイル
.p-news-newscontents__display__botton {
    border: 0;
    border-bottom: solid 1.5px var(--black);
    border-right: solid 1.5px var(--black);
    display: block;
    margin: auto 0;
    transform: rotate(45deg);
    width: 8px;
    height: 8px;
    transition: transform .4s;

    @include MQ('md') {
        margin-top: 8px;
    }
}

.p-news__newsbutton {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 1.5px var(--pink);
    position: relative;
    display: block;
    margin: 32px auto 0;
    scale: 1;
    transition: scale .4s ease;

    @include MQ('md') {
        margin-top: 28px;
        width: 40px;
        height: 40px;
    }

    &.is-active {
        &::before {
            transform: translate(-50%, -50%) rotate(225deg);
        }
    }

    &:hover {
        scale: 1.1;
    }
}

.p-news__newsbutton::before {
    content: "";
    display: block;
    border-bottom: solid 1.5px var(--pink);
    border-right: solid 1.5px var(--pink);
    transform: translate(-50%, -50%) rotate(45deg);
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: transform .4s ease;

    @include MQ('md') {
        width: 6.75px;
        height: 6.75px;
    }
}

@include MQ('lg') {
    .p-news__newsbutton{
        margin-inline: auto;
    }
}

@include MQ('md') {
    .p-news-newscontents .p-news-newscontents__left{
        display: block;
        &__display__title {
            font-size: rem(14);
            margin-left: 0rem;
            margin-top: 4px;
        }
    }
    .p-news-newsmain {
        padding: 18px 4px;
    }
    .p-news-newscontents__displaynone {
        margin-right: 0;
        margin-left: 0;
        font-size: rem(14);
        padding-inline: 4px;
    }
    
    [data-news="open"] {
        &.p-news-newscontents {
            padding-bottom: 1rem;
        }
        .p-news-newsmain {
            padding-bottom: .75rem;
        }
    }
}
@media screen and (max-width: 1050px) {
    .p-news-inner {
        margin: 0 5vw;
    }
}

.p-news__wrapper {
    // border-top: 2.5px dotted var(--gray);
    background-image : linear-gradient(to right, var(--gray) 1px, transparent 1px);;
    background-size: 5px 1px;;
    background-repeat: repeat-x;
    background-position: left top;
    overflow: hidden;
    transition: max-height .6s ease;

    @include MQ('md') {
        background-size: 6px 2px;
    }
}

[data-accordion="close"] {
    .p-news-newscontents {
        &:nth-child(n + 4) {
            opacity: 0;
            transition: opacity .6s ease;
        }
        
    }   
}

.p-news__wrapper {
    margin-top: 58px;

    @include MQ('md') {
        margin-top: 32px;
    }
}