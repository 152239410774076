@use '../Global/' as *;

.p-form {
    background: url(../images/top/contact/bg.jpg);
    background-size: cover;
    background-position: center top;
    padding: 112px 0 428px;

    @include MQ('lg') {
        padding: 88px 0 428px;
    }

    @include MQ('md') {
        padding: 42px 0 330px;
    }
}

.p-form-formcontents {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    column-gap: 40px;

    @include MQ('lg') {
        column-gap: 30px;
    }

    @include MQ('md') {
        flex-direction: column;
        align-items: center;
        row-gap: 24px;
        padding-top: 40px;
    }
}

.p-form-formcontents__content {
    width: calc(100% - 20px);

    @include MQ('lg') {
        width: calc(100% - 15px);
    }
}

.p-form-formcontents__content {

}

.p-form-formcontents__content__text {
    font-size: rem(16);
    line-height: 2;
    letter-spacing: .1em;
    text-align: center;

    @include MQ('lg') {
        font-size: rem(15);
    }

    @include MQ('md') {
        font-size: rem(14);
        line-height: 1.4;
    }
}

.p-form-formcontents__content__btn {
    display: block;
    width: 100%;
    margin-top: 12px;
    font-size: rem(32);
    font-weight: bold;
    line-height: 1;
    letter-spacing: .1em;
    text-align: center;
    color: var(--pink);
    background-color: var(--white);
    border-radius: 50vh;
    padding-block: 70px 74px;
    transition: scale .3s;

    @include MQ('lg') {
        font-size: rem(28);
        padding-block: 50px 56px;
    }

    @include MQ('md') {
        max-width: 240px;
        margin-inline: auto;
        font-size: rem(16);
        padding-block: 36px 36px;
    }

    &:hover {
        scale: 1.025;
    }

    span {
        width: fit-content;
        position: relative;

        &::after {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            border-top: 3px solid var(--pink);
            border-right: 3px solid var(--pink);
            rotate: 45deg;
            position: absolute;
            top: 50%;
            right: -32px;
            transform: translateY(-50%);

            @include MQ('md') {
                border-width: 2px;
                width: 7px;
                height: 7px;
                right: -18px;
            }
        }
    }
}