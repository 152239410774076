@use '../Global/' as *;

.p-contact {
    text-align: center;

    &__content--top {
        line-height: 1.8;
        padding-bottom: 2rem;
    }

    &__content {
        background: var(--white);
        border-radius: 50px;
        padding: 90px 78px;

        @include MQ(md) {
            padding: 40px 20px;
        }
    }

    &__item {
        background-image : linear-gradient(to right, var(--gray) 1px, transparent 1px);
        background-size: 5px 1px;
        background-repeat: repeat-x;
        background-position: left top;
        padding: 36px 16px;

        @include MQ(md) {
            background-size: 6px 2px;
            padding: 28px 0;
        }

        p {
            display: flex;
            align-items: center;
        }
    }

    &__button {
        margin-top: 30px;

        @include MQ(md) {
            margin-top: 24px;
        }
    }

    &__label {
        min-width: 200px;
        text-align: left;
        font-weight: 600;
    }

    &__check {
        display: flex;
        align-items: flex-start;

        @include MQ(md) {
            display: block;
        }

        .p-contact__label {
            @include MQ(md) {
                justify-content: flex-start;
            }
        }
    }

    &__check--list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -3vw;

        @include MQ(lg) {
            display: block;
            margin-left: 0;
        }
        @include MQ(md) {
            margin-top: 24px;
        }
    }

    &__check--item {
        width: calc(100%/2);
        padding-left: 3vw;
        margin-bottom: 24px;
        text-align: left;

        @include MQ(lg) {
            width: 100%;
            padding-left: 0;
        }

        input[type="checkbox"] {
            margin-right: 8px;
        }
    }

    &__check--note {
        font-size: rem(12);
        color: var(--pink);
    }

    &__privacy--link {
        color: var(--pink);
        text-decoration: underline;
        font-weight: 600;
    }

    &__textarea {

        p {
            align-items: flex-start;
        }
    }
}

.sp_only {
    display: none;

    @include MQ(md) {
        display: block;
    }
}

.wpcf7-text {
    height: 48px;
    padding: 0 16px;
    width: 98%;
    margin-left: 24px;

    @include MQ(md) {
        margin-left: 0;
    }

    &:-webkit-autofill{
        box-shadow: 0 0 0px 1000px var(--white) inset;
    }
}
.wpcf7-select {
    height: 48px;
    padding: 0 16px;
    margin-left: 24px;
    color: var(--black);

    @include MQ(md) {
        margin-left: 0;
    }
}

::placeholder {
    color: var(--gray);
    opacity: .6;
}

.pink {
    background: var(--pink);
    color: var(--white);
    border-radius: 50px;
    padding: 4px 10px;
    font-weight: 600;
    font-size: rem(12);
    margin-left: 24px;

    @include MQ(md) {
        font-size: rem(10);
    }
}

.contact-field {
    display: inline-block;
}

.p-contact__content textarea {
    border: 1px solid var(--black);
    padding: 1vw;
    resize: none;
    width: 49vw;
    max-width: 480px;
    height: 320px;
    line-height: 1.8;

    @include MQ(md) {
        width: 100%;
        height: 100%;
    }
}

.p-contact__check input[type="checkbox"] {
    display: none;
}

.p-contact__check input[type="checkbox"]+span {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}

.p-contact__check input[type="checkbox"]+span::before,
.p-contact__check input[type="checkbox"]+span::after {
    content: "";
    display: block;
    position: absolute;
    top: 6px;
}

.p-contact__check input[type="checkbox"]+span::before {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid var(--gray);
    left: 0;
    top: 50%;
    transform: translateY(-50%)
}

.p-contact__check input[type="checkbox"]:checked+span::before {
    border: 1px solid var(--pink); // チェック時に外側の丸のボーダーをピンクに変更
    background-color: var(--white); // チェック時の背景色を白にしてピンクの丸が目立つように
}

.p-contact__check input[type="checkbox"]+span::after {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--pink);
    left: 3.4px; // 中央に配置するために調整
    display: none; // デフォルトでは非表示
    top: 50%;
    transform: translateY(-50%)
}

.p-contact__check input[type="checkbox"]:checked+span::after {
    display: block; // チェック時に表示
}

.wpcf7-form-control-wrap .wpcf7-list-item {
    display: flex;
    align-items: center;
    justify-content: flex-start; // 内容を左寄せに
    margin: 0;
}

.wpcf7-form-control.wpcf7-checkbox {
    display: block;
    width: 100%;
    margin-bottom: -15px;
    height: auto;
}

.wpcf7-form-control.wpcf7-submit::after {
    content: '';
    display: inline-block;
    width: 8px;
    /* 矢印の幅 */
    height: 8px;
    /* 矢印の高さ */
    border-right: 2px solid var(--white);
    /* 矢印の右側の線 */
    border-top: 2px solid var(--white);
    /* 矢印の上側の線 */
    transform: rotate(45deg);
    /* 矢印を45度回転させる */
    margin-left: 1rem;
    /* テキストと矢印の間隔 */
}

.wpcf7-form-control-wrap[data-name="purpose"] {
    position: relative;
    display: inline-block;

    .wpcf7-select {
        -webkit-appearance: none;
        /* Chrome, Safari, Opera */
        -moz-appearance: none;
        /* Firefox */
        appearance: none;
        /* 標準 */
        background: transparent;
        padding-right: 1.5em;
        /* 矢印のスペースを確保 */
    }

    &:after {
        content: '';
        display: inline-block;
        width: 8px;
        /* 矢印の幅 */
        height: 8px;
        /* 矢印の高さ */
        border-right: 2px solid var(--black);
        /* 矢印の右側の線 */
        border-top: 2px solid var(--black);
        /* 矢印の上側の線 */
        transform: translateY(-50%) rotate(135deg);
        /* 矢印を45度回転させる、位置を調整 */
        position: absolute;
        right: 16px;
        /* 右端からの位置を調整 */
        top: 50%;
        /* 上端から中央に配置 */
        pointer-events: none;
        /* クリックイベントを `select` に通す */
    }
}
.explanation__sponly{
    display: none !important;
}

.p-contact__content__custom-select
{
    display: none ;
}
@include MQ('md') {
    .p-contact__row p {
        display: block;
    }

    .p-contact__row label {
        display: flex ;
        justify-content: space-between ;
        align-items: center;
        width: 100% ;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="url"],
    input[type="textarea"] {
        width: 100%;
    }
    .p-contact__row select{
        width: 100%;
    }
    .explanation__sponly{
        display: block !important;
        color: var(--pink);
        font-weight: 700;
        padding: 0.5rem 0;
    }
    .p-contact__row .pink{
        margin-right:0;
    }
    .wpcf7-form-control-wrap[data-name="textarea"]{
        margin-right: 0;
        justify-content: center;
    }
}

.wpcf7-not-valid-tip {
    position: absolute;
}
.wpcf7-form-control:focus {
    -webkit-appearance: none;
    outline: none;
}
